/* Use grungy fonts if you like */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Avenir";
  src: url("../assets/fonts/Avenir\ Next\ LT\ Pro\ Regular.otf");
}

.no-scroll {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}

.gallery-container {
  font-family: "Avenir", 'Playfair Display', serif;
  color: black;
  background-color: #333;
  padding: 2em;
  
  background-image: url("../assets/images/Potential3.jpg");
}
a{
  color: black;
}
h1 {
  align-self: flex-start;
  margin-left: 0;
  text-align: center;
  font-weight: 900;
  margin: 1em 0;
}


.gallery-container .image-gallery li .galleryImage {
  max-width: 700px;
  max-height: 850px;

  align-items: center;
}
.gallery-container .image-gallery li span {
  display: inline-block;
  padding-top: 10px;
}



.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(700px, 1fr)); /* This will create columns of 700px wide and adjust according to the screen size */
  gap: 2em; /* space between grid items */
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
}

.gallery-container .image-gallery li .galleryImage {
  max-width: 100%; /* Make it responsive to the grid item container */
  max-height: 850px;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, filter 0.3s ease-in-out;

}

.gallery-container .image-gallery li .galleryImage:hover {
  transform: scale(1.05); /* Scale up the image on hover */

}



@media screen and (max-width: 768px) {
  .image-gallery{
    display: flex;
  }
  .modal h2 {
    top: 15%; /* position it near the top */
  }
}

/* modal */
/* modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* make sure it's on top */
}

.modalImage {
  max-width: 80%;
  max-height: 80%;
  border: 5px solid white;
}

.modal h2 {
  position: absolute; /* position the text absolutely within the modal */
  bottom: 55px; /* adjust this value to control the distance from the bottom of the image */
  left: 50%; /* center the text horizontally */
  transform: translateX(-50%); /* ensure it is centered */
  color: white; /* assuming you want white text, adjust accordingly */
  z-index: 2; /* ensures the text is above the image */
}
