/* Import a suitable font */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Avenir";
  src: url("../assets/fonts/Avenir\ Next\ LT\ Pro\ Regular.otf");
}

@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "NewYork";
  src: url("../assets/fonts/NewYork.otf");
}
/* Resetting margin and padding for the whole page to eliminate any default spacing */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  /* background-image: url("../assets/images/Potential2.jpg"); */
  background-image: url("../assets/images/SpecBG.jpg");
}

#big-image {
  position: relative;
  width: 100%;
  height: 200px; /* Fixed height */
  transition: opacity 1s ease-in-out;
  border-bottom: 2px white solid;
  border-top: 2px white solid;
}

#big-image.active {
  opacity: 1;
}
#banner-image {
  position: relative; /* Changed from absolute to relative */
  width: 100%;

  height: 500px;
}

#banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 10%;
  transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.home-container {
  font-family: "Assistant", "NewYork", "Avenir", "Playfair Display", serif;

  color: rgb(255, 255, 255); /* White text for contrast */
  height: auto; /* Adjusts height based on content */

  text-align: center; /* Centered text */
}

.home-container h1 {
  margin-top: 30px;
  font-size: 6rem;
  font-weight: 100;
  margin-bottom: 0em;
  letter-spacing: 20px;
  /* text-shadow: 2px 2px 4px #000000;  */
}

.home-container p,
.home-container i {
  margin: 0.25em 0;
  font-weight: 100;
}

p {
  font-size: 1.5rem;
}

.home-container i {
  display: block; /* Making the quote its own block */
  font-style: italic; /* Italicizing quote */
}
.social-media-container {
  /* margin-right: 2.5rem;
  margin-top: 2.5rem;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center; 
  gap: 10px;  */
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
  float: none; /* Clearing any float */
  margin: 0 auto; /* Centering the container */
  text-align: center; /* Centering the content inside the container */
  display: flex; /* Using Flexbox to lay out the icons */
  justify-content: center; /* Centering icons horizontally */
  gap: 25px; /* Spacing between the icons */
  flex-direction: row;
  margin-top: 15px;
  width: 32px;
}

a {
  text-decoration: none;
  color: white;
}

.home-images-container img {
  max-height: 300px;
  padding: 20px;
  margin: 20px;
}
@media screen and (max-width: 768px) {
  .content {
    /* margin-top: 55px; */
  }
  #banner-image {
    height: 250px;
    margin: 0;
    padding: 0;
  }

  .home-images-container img {
  display: none;
  }
  .social-media-container {
    float: none; /* Clearing any float */
    margin: 0 auto; /* Centering the container */
    text-align: center; /* Centering the content inside the container */
    display: flex; /* Using Flexbox to lay out the icons */
    justify-content: center; /* Centering icons horizontally */
    gap: 35px; /* Spacing between the icons */
    flex-direction: row;
    margin-top: 25px;
  }
  .home-container h1 {
    font-size: 4rem;
    font-weight: 100;
    margin-bottom: 0em;
    letter-spacing: 10px;
    /* text-shadow: 2px 2px 4px #000000;  */
  }
}

@media screen and (min-width: 1600px) {
  #banner-image {
    height: 800px;
  }
}
