@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}
/* Basic setup */
.App {
  font-family: "Playfair Display", serif;
  background-color: transparent; /* Dark background */
  color: #f2f2f2; /* Light text */
}

/* Header */
.App-header {
  font-size: 2.5em;
  text-align: center;
  /* margin-top: 1em; */
  /* padding: 1em; */
  background: transparent; /* Slightly lighter than the main background */
  color: black;
}
h1 {
  font-weight: 400;
  animation: fadeInAnimation ease 1s;
}
p {
  font-weight: 500;
}
.about-blurb p {
 
 margin-left: 10%;
 margin-right: 10%;
  max-width: 75%;
  font-size: 1em;
  text-align: left;
  animation: fadeInAnimation ease 3s;
  padding: 1em;
  background: transparent; /* Slightly lighter than the main background */
  color: black;
}

.cover-image {
  height: 30vh; /* 30% of the viewport height */
  /* background-image: url("./assets/images/Exhibition.jpg"); */
  background-size: cover;
  background-position: center;
  margin-top: 5vh;
  
  width: 100%;

  animation: fadeInAnimation ease 4s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

body {
  /* Base color */
  overflow-x: hidden;
  background: 
    url(./assets/images/Potential1.jpg); 
   
    
  margin: none;
  padding: none;
  
}

/* Gallery */
.container h1 {
  text-align: center;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {

  /* display: none; */
}
.container {
  /* height: 100vh; */
  /* overflow: auto; */
  /* -ms-overflow-style: none;  */
  /* scrollbar-width: none;  */
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  justify-content: space-between;
  gap: 20px;
  margin: none;
}

.image-gallery > li {
  flex-basis: 50%;
  padding-bottom: 30%;
  padding-left: 20%;
  padding-right: 20%;
  list-style-type: none;
  flex-grow: 1;
}

.image-gallery li img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: baseline;
  border-radius: 0px;
}

.image-gallery li {
  
}

.image-gallery li:hover {
   /* transform: scale(1.05);   */
}

/* General styles for the About section */
.about-section h1 {
  padding-top: 15px;

  text-align: center;
}

.about-section {
  min-height: 100vh;
  background: transparent;
  color: black;
  
}

/* Flexbox container for image and text */
.about-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: auto;
}

/* Image div */
.about-image img {
  max-width: 100%; /* To ensure the image doesn't exceed its container */
  height: auto;

  /* padding: 10px; */
  margin: 20px;
  filter: saturate(0);
  border: #495734 2px solid;
}

/* Text div */
.about-text {
  font-size: 28px;
  flex: 1; /* Take up all available space */
  padding-left: 0px; /* Spacing between the image and text */
  padding-right: 40px;

}

.about-text p {
  margin-top: 16px;
}
/* Responsive design: Stacking for smaller screens */
@media (max-width: 768px) {
  .about-content {

    align-items: center;
  }
  .about-text {
    padding-left: 0; /* Reset padding */
    padding: 20px;
    text-align: left; /* Optional: Center-align text for smaller screens */
  }
  .about-blurb p {
    /* display: none; */
  }
  .about-image img {
    padding: 0px;
    margin: 0px;
    border: none;
  }
  .cover-image {
    margin-top: 1em;
  }
}

/* Contact */
.contact-section {
  font-size: 1.5em;
  text-align: center;
  padding: 1em;
  margin-top: 1em;
}

.contact-section a {
  padding: 10px;
}

/* Contact */
.contact-section {
  display: flex;
  flex-wrap: wrap; /* Allow the flex items to wrap into multiple lines */
  text-align: center;
  padding: 0.5em;
  background-color: transparent;

}

.contact-section a {
  flex: 1; /* Allow the flex items to grow and take up equal space */
  overflow: auto;
  /* padding: 10px 20px; */
  margin: 0 10px;
  text-decoration: none;
  color: black;
  /* border: 2px solid black; */
  background-color: rgba(255, 255, 255, 0.2);

}

.contact-section a:hover {
  /* background-color: #ffffff4d; */
  /* color: #602f30; */
  /* border: 2px solid #602f30; */
}

/* Media Queries */
@media (max-width: 768px) {
  .contact-section {
 /* Stack the flex items vertically */
  }

  .contact-section a {
    margin: 10px 0; /* Vertical margin instead of horizontal */
    font-size: 0.8em; /* Smaller text size */
  }
}

ul {
  margin: 0; /* To remove default bottom margin */
  padding: 15px; /* To remove default left padding */
}

.nav {
  display: flex;
  align-items: center;
  position: sticky; /* No need for the -webkit-sticky unless you're supporting very old browsers */
  top: 0; /* To prevent upward movement */
  z-index: 2;
  height: 70px;
  min-height: 70px;
  width: 100%; /* Changed to 100% */
  background-color: #fff;
  color: black;
  padding: 0 1rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.nav__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.nav__container__actions {
  display: inline-flex;
  flex-flow: row nowrap;
  padding-left: 50px;
  flex: 1;
}

.active {
  font-weight: bold;
}

.nav ul {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  list-style: none;
  padding: 0;
}

.nav li {
  cursor: pointer;
}

.nav li:hover {
  text-decoration: underline;
}


.eric{
  height: 100vh;
  
}