/* Use grungy fonts if you like */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Avenir";
  src: url("../assets/fonts/Avenir\ Next\ LT\ Pro\ Regular.otf");
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.body {
  font-family: "Assistant", "Avenir", "Playfair Display", serif;
}
.about-container {
  display: flex;
  color: black;
  background-color: #333;

  background-image: url("../assets/images/Potential3.jpg");

  align-items: center;
  overflow: hidden;
  padding-bottom: 500px;

  padding-left: 5em;
  padding-right: 5em;

  max-width: 1200px; /* Limit the maximum width */
  margin-left: auto; /* Center the container */
  margin-right: auto; /* Center the container */
}
.about-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 1em 0;
}

.about-image-left {
  float: left;
  margin-right: 20px;
  width: 500px;
}

.about-image-right {
  float: right;
  margin-left: 20px;
  width: 300px;
}

.about-text h1 {
  /* font-size: 2.5rem; */
  margin: 1em 0;

  color: #000000; /* Feel free to choose a different color */
  font-weight: 900;
}

.about-text p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 1em 0;
  margin-bottom: 5em;
}

.about-text {
  /* max-width: 1500px; */
  align-items: center;
}

/* Clear floats */
.clear {
  clear: both;
}
@media screen and (max-width: 768px) {
  .about-image-left,
  .about-image-right {
    width: 100%; /* Take full width on smaller screens */
    max-width: 100%; /* Make sure it doesn't exceed 100% */
  }
  .about-container {
    padding: none;
    padding-left: 2rem;
    padding-right: 2rem;

    flex-wrap: wrap;
    flex-direction: column;
  }

  .about-image-left {
    width: 100%;
    margin-right: 0;
    float: none; /* Clear float for small screens */
  }

  .about-image-right {
    width: 100%;
    margin-left: 0;
    float: none; /* Clear float for small screens */
  }
  .about-text h1 {
    /* font-size: 2.5rem; */
    margin: 0em 0;
    font-size: 2.5rem;
    color: #000000; /* Feel free to choose a different color */
    font-weight: 500;
  }
}
